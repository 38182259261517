<template>
  <section class="about-area pt-120 pb-90" data-aos="fade-up" data-aos-duration="1000">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-5">
          <div class="medical-icon-brand-2">
            <img src="../assets/img/about/medical-brand-icon-border.png" alt="">
          </div>
          <div class="about-left-side pos-rel mb-30">
            <div class="about-front-img default-img">
              <img :src="about.image" alt="" loading="lazy">
            </div>
            <div class="about-shape">
              <img src="../assets/img/about/about-shape.png" alt="">
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-7">
          <div class="about-right-side pt-55 mb-30">
            <div class="about-title mb-20">
              <h5>Who We Are</h5>
              <h2>{{about.title}}</h2>
            </div>
            <div class="about-text">
              <p v-html="about.description"></p><br>
            </div>
            <div class="about-author d-flex align-items-center">
              <div class="section-button text-right d-none d-lg-block pt-80">
                <router-link to="/services" class="btn btn-icon ml-0"><span>+</span>Services</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    export default {
        name: "about-area",
        props: ['about'],
        methods: {
            goToPartners() {
                  let el = document.getElementById('services');
                  if (this.$route.path === "/") {
                      setTimeout(() => {
                          el.scrollIntoView({behavior: "smooth", block: "start"});
                      }, 0);
                  }
            }
        }
    }
</script>

<style scoped>
  .default-img {
    height: 700px;
  }
  .default-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 767px) {
    .default-img {
      height: 400px;
    }
  }

  h2 {
    font-size:42px;
  }
</style>
