<template>
  <section class="hero-area">
    <div class="hero-slider">
      <div class="slider-active">
        <carousel
            v-if="headerCarousel.length !== 0"
            :dots="false"
            :nav="false"
            :autoHeight="false"
            :items="1"
            @changed="change()"
        >
          <template slot="prev" v-if="headerCarousel.length !== 1">
            <button type="button" class="slick-prev slick-arrow"><i class="fas fa-arrow-left"></i></button>
          </template>

          <div class="single-slider slider-height d-flex align-items-center"
               v-for="carousel in headerCarousel" :key="carousel.id" v-if="carousel.status === 'published'"
               :style="'background-image: url('+carousel.background_image['data']['full_url']+')'">
            <div class="container">
              <div class="row">
                <div class="col-xl-6 col-lg-8 col-md-10">
                  <div class="hero-text" v-if="!animation">
                    <div class="hero-slider-caption ">
                      <h1 v-if="carousel.id === 1" data-aos="fade-up" data-aos-offset="0" data-aos-delay="100" data-aos-duration="1000">{{carousel.title}}</h1>
                      <h2 v-if="carousel.id !== 1" data-aos="fade-up" data-aos-offset="0" data-aos-delay="100" data-aos-duration="1000">{{carousel.title}}</h2>
                      <p data-aos="fade-up" data-aos-offset="0" data-aos-delay="200" data-aos-duration="1000">{{carousel.description}}</p>
                    </div>
                    <div class="hero-slider-btn">
                      <a v-if="carousel.button1_url" :href="carousel.button1_url" target="_blank" class="btn btn-icon ml-0"
                         data-aos="fade-right" data-aos-offset="0" data-aos-delay="400" data-aos-duration="1000"><span>+</span>{{carousel.button1_text}}</a>
                      <a v-if="carousel.button2_url" :href="carousel.button2_url" target="_blank"
                         data-aos="fade-left" data-aos-offset="0" data-aos-delay="400" data-aos-duration="1000" class="play-btn popup-video"><i class="fas fa-play"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template slot="next" v-if="headerCarousel.length !== 1">
            <button type="button" class="slick-next slick-arrow" style="display: block;"><i class="fas fa-arrow-right"></i></button>
          </template>

        </carousel>
      </div>
    </div>

  </section>
</template>

<script>
    import carousel from 'vue-owl-carousel';
    export default {
        name: "hero-area",
        data() {
            return {
                animation: false
            }
        },
        components: {
            carousel
        },
        methods: {
            change() {
                  this.animation = true;
                setTimeout(()=> {
                    this.animation = false
                }, 0)
            }
        },
        props: ['headerCarousel']
    }
</script>

<style scoped>
  .slider-active {
    position: relative;
  }
  
  .single-slider  {
    background-size:cover;
  }

  h2 {
    font-size: 60px;
  }
</style>
