<template>
  <section class="appoinment-section pt-120 pb-120">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="appoinment-box white" data-aos="fade-up" data-aos-duration="1000">
            <div class="appoinment-content">
              <h2>
                <img src="../assets/img/noun_medical.png" width="80" alt="">
                How We Help
              </h2>
              <ul class="professinals-list pt-30" v-if="appointment">
                <li v-for="item in appointment" :key="item.id" v-if="item.status === 'published'">
                  <div v-html="item.description"></div>
                </li>
              </ul>
            </div>
            <router-link to="/appointment" class="btn mt-40">make appointment</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    export default {
        name: 'appointment-section',
        props: ['appointment']
    }
</script>

<style scoped>
  .appoinment-section {
    background-image: url("../assets/img/appoinment-box-bg.jpeg");
  }
  .appoinment-content h1 img {
    margin-top: -15px;
    margin-right: 10px;
  }
  @media (max-width: 400px) {
    .appoinment-content h1 img {
      margin-top: -7px;
      width: 40px;
    }
  }

  h2 {
    font-size:42px;
  }
</style>
