<template>
  <div>
    <div id="header-carousel">
      <div style="height: 900px" class="loading" v-if="loading1">
        <img src="../assets/loading-animacion.gif" alt="">
      </div>
      <heroArea data-aos="fade-up" data-aos-duration="1000" v-else :headerCarousel="headerCarousel_data"/>
    </div>
    <div id="about-area">
      <div>
        <aboutArea :about="about_data" v-if="about_data.length !== 0" />
      </div>
    </div>
    <div id="appointment-section">
      <div>
        <appointmentSection :appointment="appointment_data" />
      </div>
    </div>
    <div id="services-ares">
      <div>
        <servicesAres v-if="services_data.length !== 0" :services="services_data"/>
      </div>
    </div>
    <ctaArea />
    <div id="latest-news-area">
      <div>
        <latestNewsArea v-if="news_data.length !== 0" data-aos="fade-up" data-aos-duration="1000" :news="news_data"/>
      </div>
    </div>
  </div>
</template>

<script>
    import heroArea from '../components/hero-area';
    import servicesAres from '../components/services-area';
    import aboutArea from '../components/about-area'
    import ctaArea from '../components/cta-area';
    import latestNewsArea from '../components/latest-news-area'
    import api from "../services/api";
    import appointmentSection from "../components/appointment-section";

    export default {
        name: "home",
        data() {
            return {
                headerCarousel_data: [],
                services_data: [],
                about_data: [],
                news_data: [],
                appointment_data: [],

                loading1: true,
            }
        },
        async mounted() {
          this.loading1 = true; 
          window.scrollTo(0, 0);
            try {
                this.headerCarousel_data = await api.headerCarousel();
                this.loading1 = false;
                this.about_data = await api.about();
                this.about_data = this.about_data[0];
                let logo = await api.getFiles(this.about_data.image);
                this.about_data.image = logo.data['data']['full_url'];
                if(!this.about_data) this.about_data = [];
                this.news_data = await api.newsHomePage();
                if(!this.news_data) this.news_data = [];
                this.appointment_data = await api.helpFeatures();
                if(!this.appointment_data) this.appointment_data = [];
                this.services_data = await api.services(6);
                        for (const value of this.services_data) {
                            if (value.icon !== null && typeof value.icon === "number") {
                                let img = await api.getFiles(value.icon);
                                if (img !== null) {
                                    value.icon = img.data['data']['full_url'];
                                }
                            }
                        }
                if(!this.services_data) this.services_data = [];
            } catch {
                this.headerCarousel_data = [];
                this.about_data = [];
                this.appointment_data = [];
                this.services_data = [];
                this.news_data = []
                this.loading1 = false;
            }

        },
        components: {
            appointmentSection,
            heroArea,
            servicesAres,
            aboutArea,
            ctaArea,
            latestNewsArea
        }
    }
</script>

<style scoped>
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
