<template>
  <section class="cta-area pos-rel pt-115 pb-120">
    <div class="container">
      <div class="row" data-aos="fade-up" data-aos-duration="1000">
        <div class="col-xl-10 offset-xl-1 col-md-12">
          <div class="cta-text text-center">
            <div class="section-title pos-rel mb-50">
              <div class="section-text section-text-white pos-rel">
                <h2 class="white-color">Meet Your Doctor Virtually</h2>
              </div>
            </div>
            <div class="section-button section-button-left">
              <router-link to="/appointment" class="btn btn-icon btn-icon-green ml-0"><span>+</span>Setup an Appointment</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    export default {
        name: "cta-area"
    }
</script>

<style scoped>

  .section-text-white h5 {
    font-size: 20px;
    text-transform: uppercase;
  }

  h2 {
    font-size:54px;
  }
</style>
