<template>
  <section class="latest-news-area pt-115 pb-20">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-7">
          <div class="section-title pos-rel mb-75">
            <div class="section-icon">
              <img class="section-back-icon back-icon-left" src="../assets/img/section/section-back-icon.png" alt="">
            </div>
            <div class="section-text pos-rel">
              <h5>News</h5>
              <h2>See Articles and Videos Here</h2>
            </div>
            <div class="section-line pos-rel">
              <img src="../assets/img/shape/section-title-line.png" alt="">
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-5 d-none d-lg-block">
          <div class="section-button text-right pt-80">
            <router-link :to="{ path: '/articles', query: query({offset: 0})}" class="btn btn-icon ml-0"><span>+</span>our blog</router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-6" v-for="(item, index) in news" :key="item.id" v-if="index >=0 && index <=1">
          <div class="latest-news-box mb-30">
            <router-link :to="{ name: 'article', params: params({url: item.slug}) }" tag="div" class="latest-news-thumb mb-35 default-img">
              <img :src="item.image['data']['full_url']" alt="">
            </router-link>
            <div class="latest-news-content">
              <h3><router-link :to="{ name: 'article', params: params({url: item.slug}) }">{{item.title}}</router-link></h3>
              <p>{{item.description}}</p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12">
          <div class="recent-news-list mb-120">
            <div class="latest-news-content singl-news news-border-bottom" v-for="(item, index) in news" :key="item.id" v-if="index > 1 && index <=4">
              <h3>
                <router-link :to="{ name: 'article', params: params({url: item.slug}) }">{{item.title}}</router-link>
              </h3>
              <span class="meta-date"><i class="far fa-calendar"></i>{{moment(item.date)}}</span>
            </div>
          </div>
          <div class="mk-call-btn f-right">
            <router-link to="/appointment" class="btn btn-icon btn-icon-green ml-0"><span><i class="fas fa-phone"></i></span>Make Appointment</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    import moment from 'moment';
    export default {
        name: "latest-news-area",
        props: ['news'],
        methods: {
            moment (date) {
                return moment(date).format('Do MMM YYYY');
            },
            params(newParams) {
                return {
                    ...this.$route.params,
                    ...newParams
                }
            },
            query(newQuery) {
                return {
                    ...this.$route.query,
                    ...newQuery
                }
            },
        }
    }
</script>

<style scoped>

  h2 {
    font-size:54px;
  }
  .default-img {
    height: 270px;
    cursor: pointer;
  }
  .default-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .mk-call-btn {
    margin-bottom: 60px;
  }

</style>
