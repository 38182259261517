<template>
  <section class="servcies-area gray-bg pt-115 pb-90" id="services">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="1000" v-for="service in services" :key="service.id" v-if="service.status === 'published'">
          <div class="service-box text-center mb-30">
            <div class="service-thumb">
              <img :src="service.icon" alt="">
            </div>
            <div class="service-content">
              <h3><a>{{service.title}}</a></h3>
              <p>{{service.description}}</p>
              <router-link to="/services" class="service-link" >Read more</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="services && services.length === 6">
        <div class="section-button section-button-left">
          <router-link to="/services" class="btn btn-icon btn-icon-green ml-0">
            <span>+</span>See All services
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    export default {
        name: "services-area",
        props: ['services']
    }
</script>

<style scoped>
  .section-button.section-button-left {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .service-content>h3>a:hover {
    color: #223645;
  }
  .service-content p {
    text-align: left;
  }
  .service-thumb img {
    width: 100px;
  }
</style>
